import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/Template.js";
import AnalyticsScript from 'src/components/Analytics';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`About`}</h1>
    <hr></hr>
    <p>{`Meme Insider is a group of several dozen members who work hard to create high quality monthly online and print magazines. Meme Insider was started in November 2016 due to a certain popular reddit thread. After much work, organization, and management changes, we have created a magazine that is stable, and is able to meet the deadline.`}</p>
    <p>{`If you have any questions contact us at `}<a parentName="p" {...{
        "href": "mailto:contact@memeinsider.com"
      }}>{`contact@memeinsider.com`}</a>{`.`}</p>
    <p>{`Interested in joining our team? `}<a parentName="p" {...{
        "href": "/positions"
      }}>{`Check our available positions`}</a>{`.`}</p>
    <h2>{`Team Members`}</h2>
    <p>{`Just a bunch of goofs having a gaff.`}</p>
    <AnalyticsScript mdxType="AnalyticsScript" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      